import React from "react";

const Experience = () => {
  return (
    <section id="experience" className="pt-24">
      <ol className="relative border-s border-gray-200 dark:border-gray-700">
        <li className="mb-10 ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            June 2024 - Oct 2024
          </time>
          <h3 className="text-lg font-bold text-gray-900">
            Shine Solution Group
          </h3>
          <h3 className="text-md font-semibold text-gray-900">
            Software Engineer (Work Integrated Learning Program | Melbourne)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Built and optimized <b>CI/CD</b> pipelines with AWS CodePipeline,
            CodeBuild, and CodeCommit for automated build, testing and
            deployment{" "}
          </p>
          <p className="text-base font-normal text-gray-600">
            → Provisioned <b>AWS</b> infrastructure with <b>CloudFormation</b>{" "}
            and <b>SAM</b> for scalable <b>IaC</b> solutions.{" "}
          </p>
          <p className="text-base font-normal text-gray-600">
            → Developed and deployed a <b>serverless AI-driven </b>app using{" "}
            <b>AWS Lambda (Python)</b> for the backend, with workflows
            orchestrated via Step Functions, leveraging trained Amazon
            Rekognition Custom Labels and AWS Bedrock<b> LLM</b> Mixtral 8x7B
            models.{" "}
          </p>
          <p className="text-base font-normal text-gray-600">
            → Implemented <b>unit testing</b> with pytest within the CI/CD
            pipeline to ensure code quality and reliability.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Secured AWS resources with custom IAM role configurations.
          </p>{" "}
          <p className="text-base font-normal text-gray-600">
            → Developed a responsive and user-friendly interface with
            <b> ReactJS</b>
            for the frontend.{" "}
          </p>
        </li>
        <li className="mb-10 ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            August 2023 - June 2024
          </time>
          <h3 className="text-lg font-bold text-gray-900">Insider</h3>
          <h3 className="text-md font-semibold text-gray-900">
            Senior Technical Support Consultant (Melbourne, Remote)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Provided technical support to B2B clients across APAC and resolved
            over <b>1,100 Zendesk tickets</b> with a{" "}
            <b>99.1% client satisfaction score</b>.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Fixed, troubleshooted, and conducted UAT testing, and customized
            Insider SDK <b>(HTML, CSS, and JavaScript)</b> for clients' websites
            and mobile applications to precisely match their needs.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Worked with product managers, engineers, and QAs to resolve issues
            and provide solutions to clients using <b>Agile Methodology</b> on
            the Jira board.
          </p>
        </li>
        <li className="ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            October 2021 - September 2022
          </time>
          <h3 className="text-lg font-bold text-gray-900">Insider</h3>
          <h3 className="text-md font-semibold text-gray-900">
            Senior Technical Support Consultant (Bangkok, Thailand)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Created personalized website campaigns using Insider SDK,
            including banners, popups, and countdown elements. Customized these
            elements <b>(HTML, CSS, and JavaScript)</b> to meet specific client
            requirements.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Onboarded new clients and collaborated with them to develop
            complex requirements, such as building a web service to integrate an
            airline client's website with the Insider platform. This resulted in{" "}
            <b>
              over 20+ websites and mobile applications successful integrations
            </b>
            .
          </p>
        </li>
        <li className="ms-4 mt-3 mb-10">
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            March 2021 - October 2021
          </time>
          <h3 className="text-md font-semibold text-gray-900">
            Operational Account Manager (Bangkok, Thailand)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Provided product trainings for B2B client companies in Thailand
            and guided them on how to best use the Insider platform, which
            resulted in a <b>100% client retention rate</b> for a portfolio of{" "}
            <b>$480K USD ARR</b>.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Led the integration process between the clients' website and
            mobile application with Insider SDK and worked closely with more
            than <b>16 clients</b>, achieving a{" "}
            <b>4.91 out of 5.00 satisfaction score</b>.
          </p>
        </li>
        <li className="ms-4 mb-10">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            April 2020 - March 2021
          </time>
          <h3 className="text-lg font-bold text-gray-900">LINE MAN Wongnai</h3>
          <h3 className="text-md font-semibold text-gray-900">
            Data Operations Executive (Bangkok, Thailand)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Developed and executed promotional campaigns and banner images for
            leading restaurant chains in Thailand, such as{" "}
            <b>Pizza Hut, McDonald's, and KFC</b> in LineMan application by
            using the in-house tool.
          </p>
          <p className="text-base font-normal text-gray-600">
            → Queried <b>SQL </b>for promotional and coupon quotas, and
            generated reports for account managers.
          </p>
        </li>
        <li className="ms-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            January 2020 - April 2020
          </time>
          <h3 className="text-lg font-bold text-gray-900">Agoda</h3>
          <h3 className="text-md font-semibold text-gray-900">
            Agoda Homes Service Agent (Bangkok, Thailand)
          </h3>
          <p className="text-base font-normal text-gray-600">
            → Conducted research on new campaign promotions by calling hosts for
            feedback on promotional deals and listed properties on the backend
            platform.
          </p>
        </li>
      </ol>
    </section>
  );
};

export default Experience;
