import React from "react";
const Education = ({education}) => {
  return (
    <section id="education" className="pt-24">
      <article className="flex items-start space-x-6">
        <div className="min-w-0 relative flex-auto">
          <h2 className="font-semibold text-slate-900 truncate pr-20">
          RMIT University (Melbourne, Australia)
          </h2>
          <dl className="md:mt-2 md:flex flex-wrap text-sm leading-6 font-medium">
            <div className="md:absolute top-0 right-0 flex items-center space-x-1">
              <dd>July 2023 - November 2024</dd>
              <dt className="text-sky-500">
                <span className="sr-only">Star rating</span>
                <svg width="16" height="20" fill="#1e293b">
                  <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
                </svg>
              </dt>
            </div>
            <div>
              <dt className="sr-only">Rating</dt>
              <dd className="px-1.5 ring-1 ring-slate-200 rounded">
                Master of Information Technology (GPA: 3.80)
              </dd>
            </div>
          </dl>
        </div>
      </article>
      <article className="flex items-start space-x-6 mt-5">
        <div className="min-w-0 relative flex-auto">
          <h2 className="font-semibold text-slate-900 truncate pr-20">
          Srinakharinwirot University (Bangkok, Thailand)
          </h2>
          <dl className="md:mt-2 md:flex flex-wrap text-sm leading-6 font-medium">
            <div className="md:absolute top-0 right-0 flex items-center space-x-1">
              <dd>July 2016 - April 2019</dd>
              <dt className="text-sky-500">
                <span className="sr-only">Star rating</span>
                <svg width="3" height="20" fill="currentColor">
                </svg>
              </dt>
            </div>
            <div>
              <dt className="sr-only">Rating</dt>
              <dd className="px-1.5 ring-1 ring-slate-200 rounded">
              Bachelor of Integrated Tourism Management
          Innovation
              </dd>
            </div>
          </dl>
        </div>
      </article>
     
    </section>
  );
};

export default Education;
